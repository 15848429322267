<template>
  <div id="stations-map">
    <Map ref="map" v-bind:zoom="zoom" v-on:mounted="mapMounted">
      <vl-interaction-select
        v-if="!drawing"
        ident="modify"
        v-bind:filter="filterSelection"
        v-bind:toggle-condition="() => false"
        v-on:unselect="featureDeselected"
      />

      <!-- Existing stations -->
      <vl-layer-vector v-bind:z-index="2">
        <vl-source-vector ident="stations" v-bind:features="stationFeatures" />
        <vl-style-func v-bind:factory="styleStation" />
      </vl-layer-vector>

      <vl-interaction-modify
        v-if="!drawing"
        source="modify"
        v-on:modifyend="featureModified"
      />

      <!-- Drawing new stations -->
      <vl-layer-vector v-bind:z-index="3">
        <vl-source-vector ident="draw" v-bind:features.sync="drawnFeatures" />
      </vl-layer-vector>

      <vl-interaction-draw v-if="drawing && !drawingFinished" source="draw" type="polygon" />
      <vl-interaction-modify v-if="drawing" source="draw" />
      <vl-interaction-snap v-if="drawing" source="draw" v-bind:priority="10" />
    </Map>

    <div class="drawing-message">
      <b-alert class="drawing-entrance" fade v-bind:show="drawingEntrance">
        Desenează stația de taxi.

        <b-button-group size="sm">
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>

    <div class="drawing-message">
      <b-alert class="drawing-exit" fade v-bind:show="drawingExit || (drawingExitForExisting && !drawingFinished)">
        Desenează zona de părăsire a staței.

        <b-button-group size="sm">
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>

    <div class="drawing-message">
      <b-alert class="drawing-catchment" fade v-bind:show="drawingCatchment">
        Desenează zona de acoperire a staței.

        <b-button-group size="sm">
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>

    <div class="drawing-message">
      <b-alert class="drawing-catchment" fade v-bind:show="drawingExtraCatchment && !drawingFinished">
        Desenează zona de acoperire suplimentară pentru
        <span v-if="extraCatchmentStation" class="font-weight-bold">{{ extraCatchmentStation.name }}.</span>

        <b-button-group size="sm">
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>

    <div class="drawing-message">
      <b-alert class="drawing-finished" fade v-bind:show="drawingFinished">
        Stația este completă.

        <b-button-group size="sm">
          <b-button variant="primary" v-on:click="saveStation">Salvează</b-button>
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
import lodash from "lodash";

import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import MultiPolygon from "ol/geom/MultiPolygon";
import { createStyle } from 'vuelayers/lib/ol-ext'

import { default as Map, wkt } from "@/view/content/widgets/map/MapBase.vue";

const geoJsonFormat = new GeoJSON();

function getStyleForStation(feature, showLabel) {
  const station = feature.get("station");

  const color = feature.get("color");
  const rgb   = color.slice(0, 3);
  const alpha = color[3];

  let style = {
    fillColor: color,

    strokeColor: [ ...rgb, alpha * 0.5 ],
    strokeWidth: 3,
  };

  if (feature.get("catchment") || feature.get("entrance")) {
    style = {
      ...style,

      text: station.name,
      textFont: "Poppins, Helvetica, 'sans-serif'",
      textFontSize: "8pt",

      textFillColor: [ 0, 0, 255 ],
      textStrokeColor: [ 0, 0, 255, 0.25 ],
      textBackgroundFillColor: [ 255, 255, 255 ],
      textBackgroundStrokeColor: [ 0, 0, 255 ],

      textPadding: [ 1, 2, 0, 2 ],
      textOffsetY: -20,
    };
  }

  return createStyle(style);
}

function convertGeoJsonToWkt(geoJson) {
  const feature = geoJsonFormat.readFeature(geoJson);

  return wkt.writeGeometry(feature.getGeometry());
}

function createFeature(station, geometry, color, properties) {
  const feature = new Feature({ station, geometry, color });

  feature.setProperties(properties);

  return geoJsonFormat.writeFeatureObject(feature);;
}

function createFeaturesForStation(station) {
  const catchmentGeo = wkt.readGeometry(station.catchment);
  const entranceGeo  = wkt.readGeometry(station.entrance);
  const exitGeo      = station.exit ? wkt.readGeometry(station.exit) : null;

  const catchmentFeature = createFeature(station, catchmentGeo, station.catchmentColor, { catchment: true });
  const entranceFeature  = createFeature(station, entranceGeo,  station.entranceColor,  { entrance:  true });
  const exitFeature      = station.exit ? createFeature(station, exitGeo,  station.exitColor, { exit: true }) : null;

  return {
    catchment: catchmentFeature,
    entrance:  entranceFeature,
    exit:      exitFeature,
  }
}

function createStationFromFeatures(features) {
  const catchmentWkt = convertGeoJsonToWkt(features.catchment);
  const entranceWkt  = convertGeoJsonToWkt(features.entrance);
  const exitWkt      = features.exit ? convertGeoJsonToWkt(features.exit) : null;

  return {
    catchment: catchmentWkt,
    entrance:  entranceWkt,
    exit:      exitWkt,
  };
}

function updateStationWithFeature(station, feature) {
  const geoJson = geoJsonFormat.writeFeatureObject(feature);
  const wkt     = convertGeoJsonToWkt(geoJson);

  if (feature.get("catchment")) {
    station.catchment = wkt;
  } else if (feature.get("entrance")) {
    station.entrance = wkt;
  } else if (feature.get("exit")) {
    station.exit = wkt;
  }
}

function appendFeatureToStationCatchment(station, feature) {
  const newGeometry      = geoJsonFormat.readFeature(feature).getGeometry();
  const existingGeometry = wkt.readGeometry(station.catchment);

  let polygons = [];

  if (existingGeometry instanceof MultiPolygon) {
    polygons = existingGeometry.getPolygons();
  } else {
    polygons.push(existingGeometry);
  }

  polygons.push(newGeometry);

  station.catchment = wkt.writeGeometry(new MultiPolygon(polygons));
}

export default {
  components: {
    Map,
  },

  props: {
    stations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      zoom: 15,

      drawingStation: false,
      drawingExtraCatchment: false,
      drawingExitForExisting: false,
      drawnFeatures: [],

      extraCatchmentStation: null,
    };
  },

  computed: {
    stationFeatures() {
      const visible  = this.stations.filter(station => station.visible);
      const features = visible.map(createFeaturesForStation)
                              .map(station => [ station.catchment, station.exit, station.entrance ]);

      // Catchment, exit and entrance are drawn in this order to make the latter more visible.
      return lodash.compact(lodash.flatten(features));
    },

    catchments() {
      const visible  = this.stations.filter(station => station.visible);
      const features = visible.map(createFeaturesForStation)
                              .map(station => station.catchment);

      return lodash.compact(lodash.flatten(features));
    },

    entrances() {
      const visible  = this.stations.filter(station => station.visible);
      const features = visible.map(createFeaturesForStation)
                              .map(station => station.entrance);

      return lodash.compact(lodash.flatten(features));
    },

    exits() {
      const visible  = this.stations.filter(station => station.visible);
      const features = visible.map(createFeaturesForStation)
                              .map(station => station.exit);

      return lodash.compact(lodash.flatten(features));
    },

    drawing() {
      return this.drawingStation || this.drawingExtraCatchment || this.drawingExitForExisting;
    },

    drawingEntrance() {
      return this.drawingStation && this.drawnFeatures.length === 0;
    },

    drawingExit() {
      return this.drawingStation && this.drawnFeatures.length === 1;
    },

    drawingCatchment() {
      return this.drawingStation && this.drawnFeatures.length === 2;
    },

    drawingFinished() {
      switch (this.drawnFeatures.length) {
        case 1:
          return this.drawingExtraCatchment || this.drawingExitForExisting;

        case 3:
          return this.drawingStation;

        default:
          return false;
      }
    },
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    startDrawingStation() {
      this.drawingStation = true;
    },

    startDrawingExtraCatchment(station) {
      this.drawingExtraCatchment = true;
      this.extraCatchmentStation = station;
    },

    startDrawingExitForExisting(station) {
      this.drawingExitForExisting = true;
      this.extraCatchmentStation  = station;
    },

    cancelDrawing() {
      this.drawingStation         = false;
      this.drawingExtraCatchment  = false;
      this.drawingExitForExisting = false;

      this.extraCatchmentStation = null;

      this.drawnFeatures = [];
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    mapMounted() {
      const formHeight = jQuery("#stations-list").height();
      const mapHeight  = Math.max(formHeight, 680);

      this.$refs.map.resize(mapHeight);
    },

    featureModified(event) {
      event.features.forEach(feature => {
        feature.setProperties({ modified: true });
      });
    },

    featureDeselected(feature) {
      if (feature.get("modified")) {
        const station = feature.get("station");

        updateStationWithFeature(station, feature);

        this.$emit("station-modified", station);
      }
    },

    saveStation() {
      if (this.drawingStation) {
        const features = {
          catchment: this.drawnFeatures[2],
          entrance:  this.drawnFeatures[0],
          exit:      this.drawnFeatures[1],
        };

        const station = createStationFromFeatures(features);

        this.cancelDrawing();
        this.$emit("station-drawn", station);
      } else if (this.drawingExtraCatchment) {
        const station    = this.extraCatchmentStation;
        const newFeature = this.drawnFeatures[0];

        appendFeatureToStationCatchment(station, newFeature);

        this.cancelDrawing();
        this.$emit("station-modified", station);
      } else if (this.drawingExitForExisting) {
        const station    = this.extraCatchmentStation;
        const newFeature = this.drawnFeatures[0];

        station.exit = convertGeoJsonToWkt(newFeature);

        this.cancelDrawing();
        this.$emit("station-modified", station);
      }
    },

    styleStation() {
      return getStyleForStation;
    },

    styleStationFeature() {
      return getStyleForStation;
    },

    filterSelection(feature) {
      // Only features of existing stations are selectable.
      return !!feature.get("station");
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/_mixins.scss";

#stations-map {
  position: relative;

  .drawing-message {
    top: 1rem;
    left: 50%;
    position: absolute;

    pointer-events: none;

    .alert {
      left: -50%;
      position: relative;

      .btn-group {
        margin-left: 0.5rem;

        button {
          pointer-events: auto;
        }
      }
    }

    .drawing-catchment, .drawing-entrance, .drawing-exit {
      border-color: transparentize($color: $primary, $amount: 0.15);
      background-color: transparentize($color: $primary, $amount: 0.15);
    }

    .drawing-finished {
      border-color: transparentize($color: $success, $amount: 0.15);
      background-color: transparentize($color: $success, $amount: 0.15);
    }
  }
}
</style>
