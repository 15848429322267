<template>
  <b-modal
    centered
    ok-title="Salvează"
    cancel-title="Renunță"
    cancel-variant="danger"
    v-bind:title-html="`Redenumiți stația <strong>${oldName}</strong>`"
    v-on:ok="resolve(newName)"
    v-on:hide="resolve(false)"
    v-model="visible"
  >
    <b-form-input v-model="newName" v-bind:autofocus="true" v-bind:placeholder="oldName" />
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      oldName: null,
      newName: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    show(station) {
      this.oldName = station.name;
      this.newName = "";

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.oldName = null;
      this.newName = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>
