<template>
  <div>
    <b-row>
      <b-col cols="3">
        <List
          ref="list"
          v-bind:stations="stations"
          v-on:add="addStation"
          v-on:rename="renameStation"
          v-on:extend="extendStation"
          v-on:visible="showStation"
          v-on:hidden="hideStation"
          v-on:remove="removeStation"
        />
      </b-col>

      <b-col cols="9">
        <Map
          ref="map"
          v-bind:stations="stations"
          v-on:station-drawn="stationDrawn"
          v-on:station-modified="stationModified"
        />
      </b-col>
    </b-row>

    <RenameStationDialog ref="renameStationDialog" />
  </div>
</template>

<script>
import materialColor from "material-colors";
import parseColor from "parse-color";

import api from "@/core/services/api";

import List from "@/view/pages/stations/List.vue";
import Map from "@/view/pages/stations/Map.vue";

import RenameStationDialog from "@/view/content/dialogs/stations/RenameStation.vue";

var colorIndex = 0;

const rootColors = [
  materialColor.blue,
  materialColor.red,
  materialColor.green,
  materialColor.amber,
  materialColor.purple,
  materialColor.deepOrange,
  materialColor.pink,
  materialColor.teal
];

const indexedColors = [
  ...rootColors.map(color => color[600]),
  ...rootColors.map(color => color.a200),
  ...rootColors.map(color => color[900]),

  materialColor.brown[700],
  materialColor.grey[900]
];

const assignedColors = {};

function getColorForId(id) {
  var color = assignedColors[id];
  if (!color) {
    color = indexedColors[colorIndex++ % indexedColors.length];

    assignedColors[id] = color;
  }

  return parseColor(color).rgb;
}



function mapStation(station) {
  const color = getColorForId(station.id);

  return {
    ...station,

    visible: true,

    catchmentColor: [ ...color, 0.3 ],
    entranceColor:  [ ...color, 0.9 ],
    exitColor:      [ ...color, 0.5 ],
  };
}



export default {
  name: "Stations",

  components: {
    List,
    Map,
    RenameStationDialog,
  },

  data() {
    return {
      nextStationNum: 0,

      stations: [],
    };
  },

  async mounted() {
    const stations = await api.stations.list();
    const mapped   = stations.map(mapStation);

    this.stations       = mapped;
    this.nextStationNum = this.stations.length + 1;

    this.$store.dispatch("setBreadcrumb", [{ title: "Stații" }]);
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** List Events ***** ***** ***** ***** ***** \*/
    addStation() {
      this.$refs.map.startDrawingStation();
    },

    async renameStation(station) {
      const index = this.stations.findIndex(s => s.id === station.id);

      if (index !== -1) {
        const newName = await this.$refs.renameStationDialog.show(station);

        if (newName && newName.length) {
          station.name = newName;

          await api.stations.update(station);
        }
      }
    },

    extendStation(station) {
      const index = this.stations.findIndex(s => s.id === station.id);

      if (index !== -1) {
        this.$refs.map.startDrawingExtraCatchment(station);
        // this.$refs.map.startDrawingExitForExisting(station);
      }
    },

    showStation(station) {
      station.visible = true;
    },

    hideStation(station) {
      station.visible = false;
    },

    async removeStation(station) {
      const index = this.stations.findIndex(s => s.id === station.id);

      if (index !== -1) {
        const response = await this.confirmRemoveStation(station);

        if (response) {
          await api.stations.delete(station);

          this.stations.splice(index, 1);
        }
      }
    },

    /*\ ***** ***** ***** ***** ***** Map Events ***** ***** ***** ***** ***** \*/
    async stationDrawn(station) {
      const num = this.nextStationNum++;

      const request = { name: `Stație ${num}`, ...station };
      const created = await api.stations.create(request)
      const mapped  = mapStation(created);

      this.stations.push(mapped);
    },

    async stationModified(station) {
      const updated = await api.stations.update(station);
      const mapped  = mapStation(updated);

      const index = this.stations.findIndex(s => s.id === station.id);

      if (index !== -1) {
        this.$set(this.stations, index, mapped);
      } else {
        this.stations.push(mapped);
      }
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    confirmRemoveStation(station) {
      const message = this.$createElement("p", { class: [ "mb-0", "font-size-h6", "text-center" ] }, [
        "Sunteți sigur că doriți să ștergeți stația ",
        this.$createElement("strong", station.name),
        "?"
      ]);

      const options = {
        centered: true,

        okTitle: "Șterge",
        okVariant: "danger",

        cancelTitle: "Renunță",

        footerClass: "justify-content-between",
      };

      return this.$bvModal.msgBoxConfirm(message, options);
    },
  },
};
</script>
